<template>
    <div class="layout-header-section d-lg-flex d-block justify-content-between">
        <div class="header-label col-lg-6 col-12">New Announcement</div>
    </div>
    <div class="layout-content-section">
        <div class="
          content-header-section
          d-flex
          justify-content-start
          align-items-center
        ">
            <div class="content-subheader-box">Create New Announcement</div>
        </div>
        <div class="company-detail-box-outer" style="height: calc(100vh - 201px);">
            <div class="row company-section-outer">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-12">
                            <div class="custom-form-group">
                                <label for="formLastNameInput" class="form-label">Select Announcement Type<span
                                        class="text-danger">*</span></label>
                                <div class="custom-group-radio-box-btn">
                                    <input type="radio" class="btn-check" v-model="announcementtype" name="language-option"
                                        id="intelligentbtn" autocomplete="off" value="1" />
                                    <label class="btn custom-radio-box-btn text-capitalize" for="intelligentbtn">Intelligent
                                        Announcement</label>
                                    <input type="radio" class="btn-check" v-model="announcementtype" name="language-option"
                                        id="whatsappbtn" autocomplete="off" value="2" />
                                    <label class="btn custom-radio-box-btn text-capitalize"
                                        for="whatsappbtn">WhatsApp</label>
                                    <input type="radio" class="btn-check" v-model="announcementtype" name="language-option"
                                        id="imessagesbtn" autocomplete="off" value="3" />
                                    <label class="btn custom-radio-box-btn" for="imessagesbtn">RCS/
                                        iMessage</label>
                                    <input type="radio" class="btn-check" v-model="announcementtype" name="language-option"
                                        id="smsbtn" autocomplete="off" value="4" />
                                    <label class="btn custom-radio-box-btn text-capitalize" for="smsbtn">SMS</label>
                                    <input type="radio" class="btn-check" v-model="announcementtype" name="language-option"
                                        id="emailbtn" autocomplete="off" value="5" />
                                    <label class="btn custom-radio-box-btn text-capitalize" for="emailbtn">Email</label>
                                    <input type="radio" class="btn-check" v-model="announcementtype" name="language-option"
                                        id="audiocallbtn" autocomplete="off" value="6" />
                                    <label class="btn custom-radio-box-btn text-capitalize" for="audiocallbtn">Audio
                                        Call</label>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-12" v-if="announcementtype == 1">
                            <div class="custom-form-group">
                                <label class="">In intelligent / smart campaign, the CM Connect system determines the citizens's interest and inclination toward different media considering his/her previous response to announcements and information is sent on his preferred channel. This is a cost effective & result oriented channel.</label>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-12" v-if="announcementtype != ''">
                            <div class="custom-form-group">
                                <label class="form-label">Template Name <span class="text-danger">*</span></label>
                                <input type="text" v-model="templatename" class="form-control"
                                    placeholder="Enter Template Name" maxlength="50" autocomplete="off" />
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-12" v-if="announcementtype == 2 || announcementtype == 3">
                            <div class="custom-form-group">
                                <label class="form-label">Select Header <span class="text-danger">*</span></label>
                                <div class="d-flex justify-content-start">
                                <div class="card card-media text-center p-2 cursor-pointer me-3" style="width: 8rem;">
                                    <i class="pi pi-image" style="font-size: 5rem"></i>
                                    <div class="card-body p-0 pt-2">
                                        <p class="card-text">Image</p>
                                    </div>
                                </div>
                                <div class="card card-media text-center p-2 cursor-pointer me-3" style="width: 8rem;">
                                    <i class="pi pi-video" style="font-size: 5rem"></i>
                                    <div class="card-body p-0 pt-2">
                                        <p class="card-text">Video</p>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-12" v-if="announcementtype == 6">
                            <div class="custom-form-group">
                                <label class="form-label">Select Header <span class="text-danger">*</span></label>
                                <div class="d-flex justify-content-start">
                                <div class="card card-media text-center p-2 cursor-pointer me-3" style="width: 8rem;">
                                    <i class="pi pi-volume-up" style="font-size: 5rem"></i>
                                    <div class="card-body p-0 pt-2">
                                        <p class="card-text">Audio Clip</p>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-12" v-if="announcementtype == 1 || announcementtype == 2 || announcementtype == 3 || announcementtype == 4 || announcementtype == 5">
                            <div class="custom-form-group">
                                <label for="formmessageInput" class="form-label">Message <span
                                        class="text-danger">*</span></label>
                                <textarea type="text" v-model="pollmessage" class="form-control" id="formmessageInput"
                                    placeholder="Enter Message" autocomplete="off" rows="3"></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="custom-form-group text-center">
                                <button type="button" class="btn btn-primary">Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            announcementtype: 1,
            templatename: '',
            pollmessage: '',

        };
    },
    mounted() {
    },
    methods: {
    },
};
</script>
<style scoped>
.company-header-box-outer {
    padding: 9px 16px;
    border: 1px solid #e0e5ed;
    border-radius: 4px 4px 0px 0px;
    border-bottom: 0;
}

.company-legal-name {
    font-family: "AcuminPro-SemiBold";
    letter-spacing: 0px;
    color: #2b2e38;
    font-size: 16px;
    line-height: 20px;
    white-space: break-spaces;
}

.company-detail-box-outer {
    padding: 16px 16px 0;
    margin-bottom: 15px;
    border: 1px solid #e0e5ed;
    border-radius: 0px 0px 4px 4px;
}

.branch-detail-box-outer {
    border: 1px solid #e0e5ed;
    border-radius: 0px 0px 4px 4px;
}

.branch-detail-box-outer .branch-row-box {
    padding: 16px;
    border-bottom: 1px solid #e0e5ed;
}

.branch-detail-box-outer .branch-row-box:last-child {
    border-bottom: 0;
}

.customer-label-group {
    margin-bottom: 16px;
}

.form-label-header {
    font-family: "AcuminPro-SemiBold";
    font-size: 16px;
    letter-spacing: 0.32px;
    color: #2b2e38;
    line-height: 16px;
}

.customer-label-group .form-label {
    font-family: "AcuminPro-Regular";
    font-size: 13px;
    letter-spacing: 0px;
    color: #4a5463;
    line-height: 13px;
    margin-bottom: 6px;
}

.customer-label-group .from-label-value {
    font-family: "AcuminPro-SemiBold";
    font-size: 14px;
    letter-spacing: 0.28px;
    color: #2b2e38;
    line-height: 20px;
}

.company-section-outer {
    display: block;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
}

.company-section-outer::-webkit-scrollbar {
    display: block;
}

.company-section-outer::-webkit-scrollbar {
    width: 0.3em;
    height: 0.3em;
}

.company-section-outer::-webkit-scrollbar-thumb {
    background-color: rgb(113 115 119 / 49%);
    border-radius: 3px;
}

.custom-outline-whatsapp-btn {
    background: #e4f8f0 0% 0% no-repeat padding-box;
    border: 1px solid #bbd9cc;
    border-radius: 4px;
    padding: 5px 6px;
    line-height: 10px;
}

.custom-outline-whatsapp-btn .whatsapp-color {
    color: #2ca270;
    font-size: 18px;
}

.custom-outline-call-btn {
    background: #e4f8f0 0% 0% no-repeat padding-box;
    border: 1px solid #bbd9cc;
    border-radius: 4px;
    padding: 7.5px;
    line-height: 10px;
}

.custom-outline-call-btn .call-btn-color {
    color: #2ca270;
    font-size: 13px;
}

.custom-outline-ellipsis-btn {
    padding: 0;
    border: 0;
}

.text-star-blank {
    color: #c1c5c9;
}
.card-media:hover {
    border: 1px solid #7698cf;
    opacity: 0.6;
}</style>